import React from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";
import { Color } from "../../utils/color";

import { Breadcrumbs } from "../../components/breadcrumbs/breadcrumbs";
import { Layout } from "../../components/layout/layout";
import { ContainerWrapper } from "../../components/wrapper/containerWrapper";
import { SectionHeading } from "../../components/heading/sectionHeading";
import { Flow } from "../../components/form/flow";

const ThanksPage = () => {
  return (
    <Layout
      title="送信完了 | LIME"
      jpUrl="/contact/thanks/"
      enUrl="/en/contact/"
    >
      <Breadcrumbs>
        <Link to="/" tabIndex="-1">
          ホーム
        </Link>
        <span>/</span>
        <Link to="/contact/" tabIndex="-1">
          お問い合わせ
        </Link>
        <span>/</span>
        <Link to="/contact/thanks/" tabIndex="-1">
          送信完了
        </Link>
        <span>/</span>
      </Breadcrumbs>
      <SSection>
        <ContainerWrapper>
          <SHeading>
            <SectionHeading jp="お問い合わせ" en="Contact" position="center" />
          </SHeading>
          <Flow flow01="false" flow02="false" flow03="true" />
          <SForm>
            <h3>お問い合わせありがとうございます。</h3>
            <p>
              内容の確認後、XX営業日以内に担当者よりご連絡させていただきます。
            </p>
            <Link to="/">トップページに戻る</Link>
          </SForm>
        </ContainerWrapper>
      </SSection>
    </Layout>
  );
};

export default ThanksPage;

const SSection = styled.section`
  padding: 4rem 0;
  /* ---------- */
  /* TB */
  @media screen and (max-width: 1023px) {
    padding: 2rem 0;
    width: 100%;
    .container {
      flex-direction: column;
    }
  }
  /* ---------- */
  /* TB */
  @media screen and (max-width: 599px) {
  }
`;

const SHeading = styled.div`
  text-align: center;
`;

const SForm = styled.div`
  margin-top: 2rem;
  text-align: center;
  h3 {
    color: ${Color.primary};
  }
  p {
    margin-top: 1rem;
    color: ${Color.dark};
    font-weight: 500;
  }
  a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 2rem;
    border-radius: 8px;
    background-color: ${Color.primary};
    text-decoration: none;
    color: ${Color.light};
    font-size: 0.9rem;
    font-weight: 500;
    margin-top: 3rem;
  }
  /* TB */
  @media screen and (max-width: 1023px) {
    width: 100%;
    a {
      width: 100%;
    }
  }
`;
